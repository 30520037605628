import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';

import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { MyPortfolioService } from 'src/app/features/portfolio/services/my-portfolio.service';
import { ComplianceHistoryService } from './compliance-history.service';
import { PermissionsState } from 'src/app/stores/access-control/access-control.reducers';
import { Store } from '@ngrx/store';

@Injectable({
   providedIn: 'root',
})
export class MusaffaRedirectService {
    canReadComplianceHistory: boolean = false;
   constructor(
      private http: HttpClient,
      private router: Router,
      private subscriptionService: SubscriptionService,
      private route: ActivatedRoute,
      private myPortfolioService: MyPortfolioService,
      private complianceHistoryService: ComplianceHistoryService,
       private permissionStore: Store<{ permissions: PermissionsState }>,
   ) {
      this.permissionStore
            .select(({ permissions }) => permissions.permissions)
            .subscribe(async (permissions) => {
               this.canReadComplianceHistory = permissions.stock_shariah_compliance_history;
            })
   }

   async goToPage(module: string, recordId: string | null) {
      let path = '';
      let queryParams: any = {};
      let state: any={};

      if (module == 'home') {
         path = '/';
      } else if (module == 'stock_details') {
         if (recordId) {
            path = '/stock/' + recordId;
         } else {
            path = '/';
         }
      }else if (module == 'compliance_history') {
         if (recordId) {
            path = '/stock/' + recordId;
            if(this.canReadComplianceHistory)this.complianceHistoryService.showComplianceHistory();
         } else {
            path = '/';
         }
      } else if (module == 'etf_details') {
         if (recordId) {
            path = '/etf/' + recordId;
         } else {
            path = '/etf-screener';
         }
      } else if (module == 'purification') {
         path = '/portfolio-dashboard';
         this.myPortfolioService.setTab('purification');
         state = { id: recordId };

      } else if (module == 'portfolio') {
         path = '/portfolio-dashboard';
         state = { id: recordId };
      } else if (module == 'stock_screener') {
         path = '/stock-screener';
      } else if (module == 'etf_screener') {
         path = '/etf-screener';
      } else if (module == 'watchlist') {
         if (recordId) {
            path = '/watchlist-lists';
            queryParams = { id: recordId };
         } else {
            path = '/watchlist-lists';
         }
      } else if (module == 'public_list_details') {
         console.log(recordId, "you");
         if (recordId) {
            console.log(recordId, "i was here");
            path = '/list-details/' + recordId;
         } else {
            path = '/public-lists';
            queryParams = { lists: 'all' };
         }
      } else if (module == 'public_list') {
         path = '/public-lists';
         queryParams = { lists: 'all' };
      } else if (module == 'request_coverage') {
         path = '/stock-requests';
         queryParams = { state: '0' };
      }else if (module == 'stock_updation_request') {
         path = '/stock-requests';
         queryParams = { state: '1' };
      }else if (module == 'academy') {
         path = '/academy-articles';
      } else if (module == 'purification_calculator') {
         path = '/purification';
      } else if (module == 'zakat_calculator') {
         path = '/zakat-calculator';
      } else if (module == 'halal_collections') {
         path = '/overall-buckets';
         queryParams = { state: 0 };
      } else if (module == 'trending_halal_collections') {
         path = '/overall-buckets';
         queryParams = { state: 1 };
      } else if (module == 'super_investors') {
         path = '/super-investors';
      } else if (module == 'super_investor_details') {
         if (recordId) {
            path = '/super-investor-portfolio/' + recordId;
         }else{
            path = '/super-investors';
         }
      } else if (module == 'paywall') {
         setTimeout(() => {
            this.subscriptionService.openPaywallModal();
         }, 2000);
      }
      if (path?.length) {
         this.router.navigate([path], {
            replaceUrl: true,
            relativeTo: this.route,
            queryParams: queryParams,
            state: state
         });
      }
   }
}
