import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable, of, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
   MarketCoverage,
   MarketCoverageResponse,
} from '../models/market-coverage.model';
import { PeriodRole, Role } from 'src/app/models/subscription-info.model';

interface Feature {
   isAvailable: boolean;
   name: string;
   featureCount: number;
   featureInfo: string;
}

@Injectable({
   providedIn: 'root',
})
export class PricingService {
   url: string = environment.apiUrl;
   roleHierarchy: PeriodRole[] = [
      'free',
      'starter_monthly',
      'starter_yearly',
      'premium_monthly',
      'premium_yearly',
      'premium_plus_monthly',
      'premium_plus_yearly',
   ];
   private marketCoverageCache$!: Observable<MarketCoverage[]>;

   constructor(private http: HttpClient) {}

   // fetchBrokeragesCoverage<T>(countryCode: string): Observable<T> {
   //    let params = new HttpParams().set('country_code', countryCode)

   //    return this.http.post<T>(`${this.url}/api/trading/pricing/brokerage-coverage`, { params })
   // }

   fetchBrokeragesCoverage<T>(countryCode: string): Observable<T> {
      return this.http.get<T>(
         `${this.url}/api/trading/pricing/brokerage-coverage`
         // ?country_code=${countryCode}
      );
   }

   getMarketCoverage(): Observable<MarketCoverage[]> {
      this.marketCoverageCache$ =
         this.marketCoverageCache$ ??
         this.fetchMarketCoverage().pipe(shareReplay());
      return this.marketCoverageCache$;
   }

   private fetchMarketCoverage(): Observable<MarketCoverage[]> {
      return this.http
         .get<MarketCoverageResponse>(`${this.url}/api/portfolio/coverage`)
         .pipe(map((response) => response.data));
   }

   getBrokerageCountries(): Observable<any> {
      return this.http
         .get<any>(
            `${this.url}/api/trading/pricing/brokerage-coverage/countries`
         )
         .pipe(map((response) => response.data));
   }

   // fetchMarketCoverage<T>(): Observable<T> {
   //    return this.http.get<T>(`${this.url}/api/portfolio/coverage`)
   // }

   isHigherTier(role1: PeriodRole, role2: PeriodRole): boolean {
      // console.log(role1)
      // console.log(role2)
      if (role1 === null || role2 === null) return false;
      return (
         this.roleHierarchy.indexOf(role1) > this.roleHierarchy.indexOf(role2)
      );
   }

   isLowerTier(role1: PeriodRole, role2: PeriodRole): boolean {
      if (role1 === null || role2 === null) return false;
      return (
         this.roleHierarchy.indexOf(role1) < this.roleHierarchy.indexOf(role2)
      );
   }

   async getSubscriptionPlansData(countryCode: string | null) {
      const response: any = await lastValueFrom(
         this.http.get(`${this.url}/api/v2/plans?country=${countryCode}`)
      );
      const data = response.data;

      const starterPlan = {
         id: 'starter',
         type: 'Starter',
         monthlyPriceId: data.starter?.month.id,
         yearlyPriceId: data.starter?.year.id,
         monthlyPrice: data.starter?.month.price,
         yearlyPrice: data.starter?.year.price,
         currency: data.starter?.year.currency.toUpperCase(),
         planIncludes: ['Screener basic'],
      };

      const premiumPlan = {
         id: 'premium',
         type: 'Premium',
         monthlyPriceId: data.premium?.month.id,
         yearlyPriceId: data.premium?.year.id,
         monthlyPrice: data.premium?.month.price,
         yearlyPrice: data.premium?.year.price,
         currency: data.premium?.year.currency.toUpperCase(),
         planIncludes: ['Screener', 'Portfolio'],
      };

      const premiumPlusPlan = {
         id: 'premium_plus',
         type: 'Premium Plus',
         monthlyPriceId: data.premium_plus?.month.id,
         yearlyPriceId: data.premium_plus?.year.id,
         monthlyPrice: data.premium_plus?.month.price,
         yearlyPrice: data.premium_plus?.year.price,
         currency: data.premium_plus?.year.currency.toUpperCase(),
         planIncludes: ['Screener', 'Portfolio', 'Trade'],
      };

      return {
         starterPlan,
         premiumPlan,
         premiumPlusPlan,
      };
   }

   getStarterFeatures(): Feature[] {
      return [
         {
            isAvailable: true,
            name: 'Halal Status & Rating',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed Shariah Screening Report',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Halal Stock Collections',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Super Investors Portfolio',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Watchlists',
            featureCount: 2,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Manual Purification Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Zakat Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Request Stock Coverage & Updation',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Shariah Compliance History',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Advanced Filters',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Investment Checklist & Forecast',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Portfolio Compliance Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Portfolio Purification Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Manual Portfolios',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Brokerage Connected Portfolios',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Musaffa Trading',
            featureCount: 0,
            featureInfo: '',
         },
      ];
   }

   getPremiumFeatures(): Feature[] {
      return [
         {
            isAvailable: true,
            name: 'Halal Status & Rating',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed Shariah Screening Report',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Halal Stock Collections',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Super Investors Portfolio',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Watchlists',
            featureCount: 10,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Manual Purification Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Zakat Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Request Stock Coverage & Updation',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Shariah Compliance History',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Advanced Filters',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Investment Checklist & Forecast',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Portfolio Compliance Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Portfolio Purification Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Manual Portfolios',
            featureCount: 1,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Brokerage Connected Portfolios',
            featureCount: 1,
            featureInfo: '',
         },
         {
            isAvailable: false,
            name: 'Musaffa Trading',
            featureCount: 0,
            featureInfo: '',
         },
      ];
   }

   getPremiumPlusFeatures(): Feature[] {
      return [
         {
            isAvailable: true,
            name: 'Halal Status & Rating',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Detailed Shariah Screening Report',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Halal Stock Collections',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Super Investors Portfolio',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Watchlists',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Manual Purification Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Zakat Calculator',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Request Stock Coverage & Updation',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Shariah Compliance History',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Advanced Filters',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Investment Checklist & Forecast',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Portfolio Compliance Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Portfolio Purification Tracker',
            featureCount: 0,
            featureInfo: '',
         },
         {
            isAvailable: true,
            name: 'Manual Portfolios',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Brokerage Connected Portfolios',
            featureCount: 0,
            featureInfo: 'Unlimited',
         },
         {
            isAvailable: true,
            name: 'Musaffa Trading',
            featureCount: 0,
            featureInfo: '',
         },
      ];
   }
}
