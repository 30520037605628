<!-- <div
   class="modal-header d-flex border-bottom-0 flex-column align-items-start gap-1 bg-white pt-2 pb-0 px-3 pt-lg-3 px-lg-4"
   *ngIf="showTitle"
>
   <div class="d-flex align-items-center w-100 justify-content-between">
      <h3 class="fw-bold mb-0" *ngIf="view">Supported Brokerages</h3>
      <h3 class="fw-bold mb-0" *ngIf="!view">Select Your Brokerage</h3>
      <button class="btn p-1 rounded-circle shadow-none close-btn" (click)="activeModal.dismiss()" >
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#5E5E5E" viewBox="0 0 384 512" >
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
         </svg>
      </button>
   </div>
   <p class="mb-0 header-para">
      Tap on the brokerage which you have an account to initiate the login and
      holdings import process.
   </p>
</div>
<div class="modal-body p-3 p-lg-4 gap-3">
   <ng-container *ngIf="loading">
      <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]">
         <ngx-skeleton-loader
            count="1" appearance="line" animation="progress"
            [theme]="{ height: '70px', width: '100%', 'border-radius': '4px', margin: '0px' }"
         >
         </ngx-skeleton-loader>
      </div>
   </ng-container>
   <ng-container *ngIf="!loading">
      <button
         class="btn shadow-none single-brokerage"
         *ngFor="let institution of institutions"
         (click)="initiateBrokerage(institution)"
         [disabled]="isConnectLoading"
      >
         <div class="d-flex align-items-center justify-content-between gap-4">
            <div class="d-flex align-items-center gap-2">
               <div class="brokerage-image bg-white border flex-shrink-0">
                  <img [src]="institution.logo" class="d-block img-fluid" [alt]="institution.name" />
               </div>
               <h6 class="mb-0 line-clamp-1">{{ institution.name }}</h6>
            </div>
            <svg class="flex-shrink-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf=" showTitle && !(isConnectLoading && currBrokerageId === institution.id) " >
               <path d="M10 14L14 10L10 6L8.6 7.4L10.2 9H6V11H10.2L8.6 12.6L10 14ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="#1C1B1F" />
            </svg>
            <div *ngIf="isConnectLoading && currBrokerageId === institution.id" class="spinner-border spinner-border-sm" role="status" >
               <span class="visually-hidden">Loading...</span>
            </div>
            <div class="flex-shrink-0 rounded-circle bg-white" placement="left" ngbTooltip="For Purification: This brokerage does not allow importing transactions and hence needs to be added manually." *ngIf="!showTitle && !institution.is_transactions_available">
               <svg class="tooltip-btn p-0" width="20" height="20" fill="#1bad4e" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
               </svg>
            </div>
         </div>
         <div *ngIf="showTitle && !institution.is_transactions_available">
            <p class="single-brokerage__msg">
               For Purification: This brokerage does not allow importing
               transactions and hence needs to be added manually.
            </p>
         </div>
      </button>
   </ng-container>
</div>
<div class="modal-footer p-3 px-lg-4 d-flex flex-nowrap align-items-start justify-content-start gap-2 gap-lg-4" *ngIf="showTitle" >
   <svg class="flex-shrink-0 d-none d-lg-block" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <path d="M6 7V5H15V7H6ZM6 10V8H15V10H6ZM9 20H3C2.16667 20 1.45833 19.7083 0.875 19.125C0.291667 18.5417 0 17.8333 0 17V14H3V0H18V9.025C17.6667 8.99167 17.3292 9.00417 16.9875 9.0625C16.6458 9.12083 16.3167 9.225 16 9.375V2H5V14H11L9 16H2V17C2 17.2833 2.09583 17.5208 2.2875 17.7125C2.47917 17.9042 2.71667 18 3 18H9V20ZM11 20V16.925L16.525 11.425C16.675 11.275 16.8417 11.1667 17.025 11.1C17.2083 11.0333 17.3917 11 17.575 11C17.775 11 17.9667 11.0375 18.15 11.1125C18.3333 11.1875 18.5 11.3 18.65 11.45L19.575 12.375C19.7083 12.525 19.8125 12.6917 19.8875 12.875C19.9625 13.0583 20 13.2417 20 13.425C20 13.6083 19.9667 13.7958 19.9 13.9875C19.8333 14.1792 19.725 14.35 19.575 14.5L14.075 20H11ZM12.5 18.5H13.45L16.475 15.45L16.025 14.975L15.55 14.525L12.5 17.55V18.5ZM16.025 14.975L15.55 14.525L16.475 15.45L16.025 14.975Z" fill="#316A56" />
   </svg>
   <div class="d-flex m-0 flex-column gap-2 flex-grow-1">
      <div class="d-flex align-items-center justify-content-between gap-2">
         <h5 class="mb-0">Your brokerage doesn’t exist?</h5>
         <button class="btn rounded-pill text-nowrap add-button" *ngIf="userRole === 'PREMIUM'" (click)="navigateToAddPortfolio()" >
            ADD MANUALLY
         </button>
         <button class="btn rounded-pill text-nowrap add-button" *ngIf="userRole !== 'PREMIUM'" (click)="showPaywallPopup()" >
            ADD MANUALLY
         </button>
      </div>
      <p class="mb-0 manual-para">
         Couldn’t find your broker in the supported institutions list? You can
         add your holdings manually and create your portfolio compliance
         dashboard.
      </p>
   </div>
</div> -->

<div class="modal-header p-3 p-lg-4">

   <div class="brokarge-modal-header">
      <button class="p-1 btn rounded-circle bg-danger shadow-none close-btn" (click)="activeModal.dismiss()">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" color="#fff" fill="none">
            <path d="M19.0005 4.99988L5.00049 18.9999M5.00049 4.99988L19.0005 18.9999" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
         </svg>
      </button>
      <div>
         <p class="header-span">Connect your</p>
         <p class="header-heading">Broker for portfolio tracking!</p>
      </div>
      <div class="header-search">
         <!-- search box here -->
         <div class="search-main-container">
            <div class="d-flex align-items-center overflow-hidden search-input-container">
               <input class="search-input flex-grow-1" placeholder="Search Broker Here..." autocomplete="off" [(ngModel)]="searchText" (input)="searchBrokerage()" />
               <div class="search-icon-wrapper d-flex align-items-center justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" class="bi bi-search search-icon" viewBox="0 0 16 16">
                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
               </div>

               <!-- 
             (focus)="onFocus()"
              (blur)="onBlur()"
              (keydown)="handleKeyDown($event)"
           
              [value]="stockName"
              (input)="searchStock(stockName)"
            -->
            </div>
         </div>
      </div>
   </div>
   <!-- Buttons -->
   <!-- <div class="brokerage-tab-btns-wrapper mt-3">
         <ng-container *ngFor="let feature of features">
            <button class="d-flex align-items-center justify-content-between rounded-pill text-nowrap brokerage-tab-btn"
               [disabled]="loading" [ngClass]="activeFeature === feature ? 'active-tab' : ''"
               (click)="filterBrokerageByFeature(feature)">
               {{ feature }}
            </button>
         </ng-container>
      </div> -->

</div>

<div class="modal-body p-3 p-lg-4 ">
   <ng-container *ngIf="loading">
      <div class="grid-view gap-3">
         <div *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8]">
            <ngx-skeleton-loader count="1" appearance="line" animation="progress"
               [theme]="{ height: '70px', width: '100%', 'border-radius': '4px', margin: '0px' }">
            </ngx-skeleton-loader>
         </div>
      </div>
   </ng-container>
   <ng-container *ngIf="!loading">
      <!-- supported brokerages -->
      <div *ngIf="filteredBrokerages.length" class="grid-view gap-3 mb-4">
         <button class="btn shadow-none d-flex align-items-start single-brokerage" *ngFor="let brokerage of filteredBrokerages" (click)="initiateBrokerage(brokerage)" [disabled]="isConnectLoading">

            <!-- Loader layer -->
            <div *ngIf="isConnectLoading && currBrokerageId === brokerage.brokerage_id" class="single-brokerage_loader">
               <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
               </div>
            </div>

            <!-- <div class="d-flex align-items-center justify-content-between gap-4"> -->
            <div class="d-flex align-items-start gap-3">
               <img [src]="brokerage.square_logo" class="d-block bg-light img-fluid" [alt]="brokerage.name" />
               <div>
                  <h6 class="mb-2 line-clamp-1">{{ brokerage.name }}</h6>
                  <div class="features">
                     <div class="d-flex gap-2 align-items-center">
                        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M16.875 15H1.125C0.50475 15 0 14.4952 0 13.875V3.375C0 2.75475 0.50475 2.25 1.125 2.25H16.875C17.4953 2.25 18 2.75475 18 3.375V13.875C18 14.4952 17.4953 15 16.875 15ZM1.125 3C0.918 3 0.75 3.168 0.75 3.375V13.875C0.75 14.082 0.918 14.25 1.125 14.25H16.875C17.082 14.25 17.25 14.082 17.25 13.875V3.375C17.25 3.168 17.082 3 16.875 3H1.125Z" fill="#7E7E7E" />
                           <path d="M11.625 3H6.375C6.168 3 6 2.832 6 2.625V1.125C6 0.50475 6.50475 0 7.125 0H10.875C11.4952 0 12 0.50475 12 1.125V2.625C12 2.832 11.832 3 11.625 3ZM6.75 2.25H11.25V1.125C11.25 0.918 11.082 0.75 10.875 0.75H7.125C6.918 0.75 6.75 0.918 6.75 1.125V2.25Z" fill="#7E7E7E" />
                           <path d="M17.625 7.5H0.375C0.168 7.5 0 7.332 0 7.125C0 6.918 0.168 6.75 0.375 6.75H17.625C17.832 6.75 18 6.918 18 7.125C18 7.332 17.832 7.5 17.625 7.5Z" fill="#7E7E7E" />
                           <path d="M5.625 9.75H3.375C3.168 9.75 3 9.582 3 9.375V7.125C3 6.918 3.168 6.75 3.375 6.75C3.582 6.75 3.75 6.918 3.75 7.125V9H5.25V7.125C5.25 6.918 5.418 6.75 5.625 6.75C5.832 6.75 6 6.918 6 7.125V9.375C6 9.582 5.832 9.75 5.625 9.75Z" fill="#7E7E7E" />
                           <path d="M14.625 9.75H12.375C12.168 9.75 12 9.582 12 9.375V7.125C12 6.918 12.168 6.75 12.375 6.75C12.582 6.75 12.75 6.918 12.75 7.125V9H14.25V7.125C14.25 6.918 14.418 6.75 14.625 6.75C14.832 6.75 15 6.918 15 7.125V9.375C15 9.582 14.832 9.75 14.625 9.75Z" fill="#7E7E7E" />
                        </svg>
                        <span>Portfolio Tracking</span>
                     </div>
                     <div *ngIf="brokerage.supported_features.is_trading_allowed"
                        class="d-flex gap-1 align-items-center">
                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M14.75 13.6875H2.75C2.23325 13.6875 1.8125 13.2668 1.8125 12.75V0.75C1.8125 0.4395 1.5605 0.1875 1.25 0.1875C0.9395 0.1875 0.6875 0.4395 0.6875 0.75V12.75C0.6875 13.8877 1.61225 14.8125 2.75 14.8125H14.75C15.0605 14.8125 15.3125 14.5605 15.3125 14.25C15.3125 13.9395 15.0605 13.6875 14.75 13.6875Z" fill="#7E7E7E" />
                           <path d="M15.0875 3.3C14.8393 3.11325 14.4867 3.16425 14.3 3.41175L12.3695 5.9865L10.562 4.782C10.4345 4.6965 10.277 4.668 10.13 4.701C9.98075 4.73325 9.851 4.8255 9.77075 4.95525L7.81775 8.127L6.04775 7.02225C5.80025 6.86925 5.47475 6.9285 5.3 7.1625L3.05 10.1625C2.86325 10.4115 2.91425 10.764 3.1625 10.95C3.26375 11.0265 3.38225 11.0625 3.5 11.0625C3.671 11.0625 3.83975 10.9845 3.95075 10.8375L5.891 8.25075L7.703 9.381C7.82975 9.459 7.9835 9.48375 8.129 9.4515C8.2745 9.417 8.4005 9.32625 8.47925 9.19875L10.4233 6.042L12.188 7.218C12.4347 7.38225 12.77 7.32675 12.95 7.0875L15.2 4.0875C15.3868 3.8385 15.3358 3.486 15.0875 3.3Z" fill="#7E7E7E" />
                        </svg>
                        <span>Trading</span>
                     </div>
                     <div *ngIf="brokerage.supported_features.automated_purification"
                        class="d-flex gap-1 align-items-center">
                        <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M12.5 3.9375H3.5C3.35081 3.9375 3.20774 3.99679 3.10225 4.10226C2.99677 4.20773 2.9375 4.35083 2.9375 4.5C2.9375 4.64917 2.99677 4.79227 3.10225 4.89774C3.20774 5.00321 3.35081 5.0625 3.5 5.0625H12.5C12.6492 5.0625 12.7923 5.00321 12.8977 4.89774C13.0032 4.79227 13.0625 4.64917 13.0625 4.5C13.0625 4.35083 13.0032 4.20773 12.8977 4.10226C12.7923 3.99679 12.6492 3.9375 12.5 3.9375Z" fill="#7E7E7E" />
                           <path d="M15.3125 0.5625H0.6875C0.538314 0.5625 0.395242 0.621765 0.289751 0.72725C0.184265 0.832742 0.125 0.975814 0.125 1.125C0.125 1.27417 0.184265 1.41727 0.289751 1.52274C0.395242 1.62821 0.538314 1.6875 0.6875 1.6875H15.3125C15.4617 1.6875 15.6048 1.62821 15.7102 1.52274C15.8157 1.41727 15.875 1.27417 15.875 1.125C15.875 0.975814 15.8157 0.832742 15.7102 0.72725C15.6048 0.621765 15.4617 0.5625 15.3125 0.5625Z" fill="#7E7E7E" />
                           <path d="M9.6875 7.3125H6.3125C6.16333 7.3125 6.02023 7.37179 5.91476 7.47726C5.80929 7.58273 5.75 7.72583 5.75 7.875C5.75 8.02417 5.80929 8.16727 5.91476 8.27274C6.02023 8.37821 6.16333 8.4375 6.3125 8.4375H9.6875C9.83668 8.4375 9.97977 8.37821 10.0852 8.27274C10.1907 8.16727 10.25 8.02417 10.25 7.875C10.25 7.72583 10.1907 7.58273 10.0852 7.47726C9.97977 7.37179 9.83668 7.3125 9.6875 7.3125Z" fill="#7E7E7E" />
                        </svg>
                        <span>Automated Purification</span>
                     </div>
                  </div>
               </div>
            </div>
         </button>
      </div>
      <app-add-manual-card [portfolioAccounts]="portfolioAccounts"></app-add-manual-card>
      <!-- <div *ngIf="!filteredBrokerages.length">
         <p class="no-data-text my-3">No supported brokerage found.</p>
      </div> -->
   </ng-container>
</div>