import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, from, lastValueFrom, map, Observable, shareReplay, Subject, switchMap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { CountryCodeService } from './phone-code.service';
import { MarketUtilsService } from './market-utils.service';
import { User, UserResponse } from 'src/app/models/user/user.model';

@Injectable({
   providedIn: 'root',
})
export class UserApiService {
   public userData: any;
   private defaultCountryApiSuccess = new Subject<void>();
   defaultCountryApiSuccess$ = this.defaultCountryApiSuccess.asObservable();

   private updatedDefaultfaultCountryCode: string | null = null;

   private userDetails$!: Observable<User>;

   constructor(
      private http: HttpClient,
      private authService: AuthService,
      private marketUtilsService: MarketUtilsService,
      private countryCodeService: CountryCodeService
   ) {}

   // public async login(email: string, password: string) {
   //    return await lastValueFrom(
   //       this.http.post(environment.apiUrl + '/api/user/login-2', {
   //          email: email,
   //          password: password,
   //       })
   //    );
   // }

   async login<T>(email: string, password: string): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(environment.apiUrl + '/api/user/login-2', {
            email: email,
            password: password,
         })
      );
   }

   // public async sendVerificationMail(data: any) {
   //    return await lastValueFrom(
   //       this.http.post(
   //          environment.apiUrl + '/api/user/send-verification-mail-3',
   //          data
   //       )
   //    );
   // }

   async sendVerificationMail<T>(userData: { email: string; firstname: string; lastname: string; password: string }): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>( environment.apiUrl + '/api/user/send-verification-mail-3', userData )
      );
   }



   async verifyOTPCode<T>(userData: { identifier: string, activationCode: string}): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(environment.apiUrl + '/api/user/verify-otp', userData)
      );
   }

   async sendOTPMail<T>(userEmail : {email: string}): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(environment.apiUrl + '/api/user/send-otp-mail', userEmail)
      );
   }

   async sendResetInstructions<T>(userEmail : {email: string}): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(environment.apiUrl + '/api/user/forgot-password', userEmail)
      );
   }

   async resetPassword<T>(userData: { identifier: string, new_password: string}): Promise<T> {
      return await lastValueFrom(
         this.http.post<T>(environment.apiUrl + '/api/user/reset-password', userData)
      );
   }

   public async logout() {}

   public async details() {
      return await lastValueFrom(
         this.http.get(environment.apiUrl + '/api/user')
      );
   }

   public async submitBasicDetails(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/basic-details', data)
      );
   }

    async sendEnterpriseInquiry(data: any) {
      console.log(data)
      return await lastValueFrom(
         this.http.post( environment.apiUrl + '/api/enterprise-inquiry', data )
      );
   }

   async fetchUserDetails() {
      if (this.authService.isLoggedIn()) {
         if (this.userData != null) return this.userData;

         let userDetails: any = await this.details();
         this.userData = userDetails.data;
         return this.userData;
      }
      return null;
   }

   public async editUserEmail(email: string) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/send-update-mail-otp', {
            email: email,
         })
      );
   }

   public async verifyOtpForUserEmailChange(identifier: string, otp: number) {
      return await lastValueFrom(
         this.http.post(
            environment.apiUrl + '/api/user/update-mail-verify-otp',
            {
               identifier: identifier,
               activationCode: otp,
            }
         )
      );
   }

   public async submitUserOnboardingServe(data: any) {
      return await lastValueFrom(
         this.http.post(
            environment.apiUrl + '/api/customer-acquisition-survey',
            data
         )
      );
   }

   async fetchUserDetailsAndSave() {
      let userDetails: any = await this.details();
      this.userData = userDetails.data;
      await this.authService.setUserData(userDetails.data);
      return userDetails.data;
   }

   public async googleLogin(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/google-login', data)
      );
   }

   public async facebookLogin(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/facebook-login', data)
      );
   }

   public async appleLogin(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/apple-login', data)
      );
   }



   public async verifyOtp(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/verify-otp', data)
      );
   }

   public async updateUser(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/update', data)
      );
   }

   public async changePassword(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/change-password', data)
      );
   }

   public async forgotPassword(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/forgot-password', data)
      );
   }

   public async forgotPasswordChange(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/reset-password', data)
      );
   }

   public async userFeedback(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/feedback', data)
      );
   }

   public async createTicket(formData: FormData) {
      return await lastValueFrom(
         this.http.post(
            environment.apiUrl + '/api/user/create-ticket',
            formData
         )
      );
   }

   // public async createTicket(formData: any) {
   //    return await lastValueFrom(
   //      this.http.post(environment.apiUrl + '/api/user/create-ticket', formData)
   //    );
   // }

   public async getCountryFromIp() {
      return await lastValueFrom(
         this.http.get(environment.apiUrl + '/api/country')
      );
   }

   public async getUserCountryNameFromIp(): Promise<any> {
      try {
         let res: any = await this.getCountryFromIp();
         if (res.status) {
            let countryCode = res?.data?.iso_code;
            let countryCodeVal: any =
               this.countryCodeService.filterByCode(countryCode);
            localStorage.setItem('userCountry', JSON.stringify(countryCodeVal));
            return true;
         } else return false;
      } catch (e) {
         return false;
      }
   }

   public async sendOtpMail(data: any) {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/send-otp-mail', data)
      );
   }

   public async requestStockCoverage(
      ticker: string,
      country: string,
      is_covered_country: boolean | undefined
   ) {
      if (!is_covered_country) {
         const countryInfo = await this.marketUtilsService.fetchByCountryCode(
            country
         );
         is_covered_country = countryInfo?.detailedProfileAvailable ?? false;
      }
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/request-coverage', {
            ticker,
            country,
            is_covered_country,
         })
      );
   }

   public async hasCoverageRequestFor(ticker: string): Promise<any> {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/has-coverage-request', {
            ticker,
         })
      );
   }

   public async myCoverageRequests(
      startDate?: Date,
      endDate?: Date,
      isCoveredCountry?: boolean,
      status?: string
   ) {
      let queryParams = new HttpParams();
      if (status) queryParams = queryParams.append('status', status);
      if (isCoveredCountry !== undefined)
         queryParams = queryParams.append(
            'is_covered_country',
            isCoveredCountry
         );
      if (startDate) {
         const start = startDate.toISOString().split('T')[0];
         queryParams = queryParams.append('start_date', start);
      }
      if (endDate) {
         const end = endDate.toISOString().split('T')[0];
         queryParams = queryParams.append('end_date', end);
      }

      return await lastValueFrom(
         this.http.get(environment.apiUrl + '/api/user/my-coverage-requests', {
            params: queryParams,
         })
      );
   }

   async updateUsersDefaultCountry(countryCode: string): Promise<any> {
      return await lastValueFrom(
         this.http.post(
            environment.apiUrl + '/api/user/update-default-country',
            {
               countryCode,
            }
         )
      );
   }

   emitDefaultCountryApiSuccess() {
      this.defaultCountryApiSuccess.next();
   }

   setDefaultCountryCode(countryCode: string) {
      this.updatedDefaultfaultCountryCode = countryCode;
   }

   getDefaultCountryCode(): string | null {
      return this.updatedDefaultfaultCountryCode;
   }

   async getUsersRequestedStocks() {
      return await lastValueFrom(
         this.http.post(environment.apiUrl + '/api/user/fetch-request', {})
      );
   }

   async getUsersUpdateRequests() {
      return await lastValueFrom(
         this.http.get(environment.apiUrl + '/api/user/my-update-requests', {})
      );
   }

   getUserData(): Observable<User> {
      this.userDetails$ = this.userDetails$ ?? this.fetchUserData().pipe(shareReplay());
      return this.userDetails$;
   }
  
   private fetchUserData(): Observable<User> {
      return this.http.get<UserResponse>(environment.apiUrl + '/api/user').pipe(
         map((response) => response.data ),
         catchError((error) => {
            console.error('Error fetching user data', error);
            return throwError(() => error);
         })
      );
   }

   // getUserData(): Observable<User> {
   //    if (!this.userDetails$) {
   //      this.userDetails$ = this.fetchUserData().pipe(shareReplay());
   //    }
   //    return this.userDetails$;
   // }
  
   // refetchUserData(): Observable<User> {
   //    this.userDetails$ = this.fetchUserData().pipe(shareReplay());
   //    return this.userDetails$;
   // }
  
   
   // private fetchUserData(): Observable<User> {
   //    return this.http.get<UserResponse>(`${environment.apiUrl}/api/user`).pipe(
   //       switchMap((response) => {
   //          this.userData = response.data;
   //          return from(this.authService.setUserData(response.data)).pipe(
   //             map(() => response.data)
   //          );
   //       }),
   //       catchError((error) => {
   //          console.error('Error fetching user data', error);
   //          return throwError(() => error);
   //       })
   //    );
   // }
}
