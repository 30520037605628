import { Component, Input, OnInit } from '@angular/core';
import { PortfolioService } from '../../services/portfolio.service';
import { SupportedBrokerages } from 'src/app/models/supported-institution.model';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { SubscriptionInfoModel } from 'src/app/models/subscription-info.model';
import { SubscriptionService } from '../../services/subscription.service';
import { ConnectIframeModalComponent } from '../connect-iframe-modal/connect-iframe-modal.component';
import { PaywallPopupComponent } from 'src/app/shared/components/paywall-popup/paywall-popup.component';
import { SharedService } from '../../services/shared.service';
import { AuthService } from '../../services/auth.service';
import {
   BrokerageCoverageApiResponse,
   CoverageBrokerage,
} from 'src/app/features/pricing/models/brokerage-coverage.model';
import { PricingService } from 'src/app/features/pricing/services/pricing.service';
import { PortfolioAccount } from 'src/app/models/portfolio/portfolio-list.model';

@Component({
   selector: 'app-supported-institutions',
   templateUrl: './supported-institutions.component.html',
   styleUrls: ['./supported-institutions.component.scss'],
})
export class SupportedInstitutionsComponent implements OnInit {
   private destroy$: Subject<void> = new Subject<void>();
   features: string[] = [
      'Portfolio Tracking',
      'Trading',
      'Automated Purification',
   ];
   activeFeature: string = this.features[0];
   allSupportedBrokerages: CoverageBrokerage[] = [];
   supportedBrokerages: CoverageBrokerage[] = [];
   filteredBrokerages: CoverageBrokerage[] = [];
   loading: boolean = false;
   private subscriptions: Subscription[];
   userRole: string | null = null;
   redirectUrl: string | null = null;
   connectIframeModalRef: any;
   isConnectLoading: boolean = false;
   currBrokerageId: any;
   paywallModalRef: any;
   searchText: string = '';
   sectionLoader: boolean = true;

   portfolioAccounts: PortfolioAccount[] = [];

   @Input() view: boolean = true;
   @Input() showTitle: boolean = true;
   @Input() perRow: number = 3;

   constructor(
      private portfolioServices: PortfolioService,
      public activeModal: NgbActiveModal,
      private modalService: NgbModal,
      private router: Router,
      private authService: AuthService,
      private sharedService: SharedService,
      private subscriptionService: SubscriptionService,
      private pricingService: PricingService,
      private portfolioService: PortfolioService
   ) {
      this.subscriptions = [];
   }

   async ngOnInit() {
      await this.getPortfolioList();
      this.loading = true;
      try {
         const res = await lastValueFrom(
            this.pricingService.fetchBrokeragesCoverage<BrokerageCoverageApiResponse>(
               'all'
            )
         );
         this.allSupportedBrokerages = res.data;
         this.filterBrokerageByFeature(this.activeFeature);
      } catch (err: any) {
         console.log(err);
      } finally {
         this.loading = false;
      }
   }

   private async getPortfolioList() {
      this.sectionLoader = true;
      this.portfolioService
         .getPortfoliosList()
         .pipe(takeUntil(this.destroy$))
         .subscribe({
            next: (response) => {
               this.portfolioAccounts = response;
               this.sectionLoader = false;
            },
            error: (error) => {
               console.error(error);
               this.sectionLoader = false;
            },
         });
   }

   filterBrokerageByFeature(feature: string) {
      this.activeFeature = feature;
      switch (feature) {
         case 'Portfolio Tracking': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.is_portfolio_linking_allowed
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
         case 'Trading': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.is_trading_allowed
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
         case 'Automated Purification': {
            this.supportedBrokerages = this.allSupportedBrokerages.filter(
               (item: CoverageBrokerage) =>
                  item.supported_features.automated_purification
            );
            this.filteredBrokerages = this.supportedBrokerages;
            this.searchText = '';
            break;
         }
      }
   }

   searchBrokerage() {
      const lowerCaseText = this.searchText.toLowerCase();
      this.filteredBrokerages = this.supportedBrokerages.filter(
         (item: CoverageBrokerage) =>
            item.name.toLowerCase().includes(lowerCaseText)
      );
   }

   openConnectIframeModal() {
      this.connectIframeModalRef = this.modalService.open(
         ConnectIframeModalComponent,
         {
            centered: true,
            backdrop: 'static',
            scrollable: true,
            modalDialogClass: 'connect-iframe-modal',
            size: 'lg',
         }
      );
      this.connectIframeModalRef.componentInstance.iframeUrl = this.redirectUrl;
      this.connectIframeModalRef.componentInstance.module = 'portfolio';
   }

   initiateBrokerage(brokerage: any) {
      // if (!this.showTitle) return;

      // if (this.userRole === 'ANONYMOUS') {
      //    this.activeModal.dismiss();
      //    this.router.navigate(['authentication/sso-login']);
      //    return;
      // }

      // if (this.userRole !== 'PREMIUM') {
      //    this.openPaywallModal();
      //    return;
      // }
      this.currBrokerageId = brokerage.brokerage_id;
      this.isConnectLoading = true;
      this.portfolioServices
         .getConnectUrl(brokerage.brokerage_id, false)
         .then((resp: any) => {
            if (resp.status) {
               this.isConnectLoading = false;
               this.activeModal.dismiss();
               this.redirectUrl = resp.data.redirect_url;
               this.openConnectIframeModal();
            }
         })
         .catch((err: any) => {
            console.log(err);
            this.isConnectLoading = false;
         });
   }

   // <------ old code ------->

   // @Input() view: boolean = true;
   // @Input() showTitle: boolean = true;
   // @Input() perRow: number = 3;
   // institutions: SupportedBrokerages[] = [];
   // loading: boolean = false;
   // currentScreen: string = 'default';
   // private subscriptions: Subscription[];
   // userRole: string | null = null;
   // redirectUrl: string | null = null;
   // connectIframeModalRef: any;
   // isConnectLoading: boolean = false;
   // currBrokerageId: any;
   // paywallModalRef: any;

   // constructor(
   //    private portfolioServices: PortfolioService,
   //    public activeModal: NgbActiveModal,
   //    private modalService: NgbModal,
   //    private router: Router,
   //    private authService: AuthService,
   //    private sharedService: SharedService,
   //    private subscriptionService: SubscriptionService
   // ) {
   //    this.subscriptions = [];
   // }

   // ngOnInit(): void {
   //    this.subscriptions.push(
   //       SubscriptionService.subscriptionData.subscribe(
   //          (data: SubscriptionInfoModel) => {
   //             this.userRole = data.role;
   //          }
   //       )
   //    );
   //    this.portfolioServices.getBrokerageScreen().subscribe((value: string) => {
   //       this.currentScreen = value;
   //    });
   //    this.getAllBrokerages();
   // }

   // ngOnDestroy(): void {
   //    if (this.subscriptions && this.subscriptions.length > 0) {
   //       this.subscriptions.forEach((item) => {
   //          item.unsubscribe();
   //       });
   //    }
   // }

   // openPaywallModal() {
   //    this.activeModal.dismiss();
   //    this.paywallModalRef = this.modalService.open(PaywallPopupComponent, {
   //       centered: true,
   //       modalDialogClass: 'paywall-popup-modal',
   //       size: 'xl',
   //    });
   // }

   // getAllBrokerages() {
   //    this.loading = true;
   //    this.portfolioServices.getSupportedBrokerages().then(
   //       (results) => {
   //          this.institutions = results.data;

   //          // if (this.currentScreen === 'Compliance') {
   //          //    console.log('filtering');
   //          //    this.institutions = this.institutions.filter(
   //          //       (item) => item.is_transactions_available === true
   //          //    );
   //          // }

   //          // if (this.currentScreen === 'Purification') {
   //          //    this.institutions = this.institutions.filter(
   //          //       (item) => item.is_transactions_available === false
   //          //    );
   //          // }

   //          // console.log(this.currentScreen, this.institutions);

   //          this.loading = false;
   //       },
   //       (error) => {
   //          console.error('Error fetching data:', error);
   //          this.loading = false;
   //       }
   //    );
   // }

   // openConnectIframeModal() {
   //    this.connectIframeModalRef = this.modalService.open(
   //       ConnectIframeModalComponent,
   //       {
   //          centered: true,
   //          backdrop: 'static',
   //          scrollable: true,
   //          modalDialogClass: 'connect-iframe-modal',
   //          size: 'lg',
   //       }
   //    );
   //    this.connectIframeModalRef.componentInstance.iframeUrl = this.redirectUrl;
   //    this.connectIframeModalRef.componentInstance.module = 'portfolio';
   // }

   // initiateBrokerage(brokerage: any) {
   //    if (!this.showTitle) return;

   //    if (this.userRole === 'ANONYMOUS') {
   //       this.activeModal.dismiss();
   //       this.authService.openAuthPopup()
   //       // this.router.navigate(['authentication/sso-login']);
   //       return;
   //    }

   //    if (this.userRole !== 'PREMIUM') {
   //       // this.openPaywallModal();
   //       this.sharedService.openUpgradePopup('portfolio')
   //       // this.authService.openAuthPopup()
   //       return;
   //    }
   //    this.currBrokerageId = brokerage.id;
   //    this.isConnectLoading = true;
   //    this.portfolioServices
   //       .getConnectUrl(brokerage.id, false)
   //       .then((resp: any) => {
   //          if (resp.status) {
   //             this.isConnectLoading = false;
   //             this.activeModal.dismiss();
   //             this.redirectUrl = resp.data.redirect_url;
   //             this.openConnectIframeModal();
   //          }
   //       });
   // }

   // navigateToAddPortfolio() {
   //    this.portfolioServices.setBrokerageId('');
   //    this.portfolioServices.setHasBrokerageId(false);
   //    this.activeModal.dismiss();
   //    this.router.navigate(['/cabinet/add-portfolio']);
   // }

   // showPaywallPopup() {
   //    this.activeModal.dismiss()
   //    this.authService.openAuthPopup()
   //    // this.subscriptionService.openPaywallModal();
   // }
}
